import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ModalService } from '../modal/service/modal.service';
import { ModalComponent } from '../modal/modal.component';
import { bounceAnimation } from 'angular-animations';

@Component({
  selector: 'app-modal-confirmation',
  standalone: true,
  imports: [ModalComponent],
  templateUrl: './modal-confirmation.component.html',
  styleUrl: './modal-confirmation.component.scss',
  animations: [bounceAnimation()],
})
export class ModalConfirmationComponent {
  @Input({
    required: true,
  })
  public modalId!: string;

  @Input({
    required: true,
  })
  public title!: string;

  @Input({
    required: true,
  })
  public message!: string;

  @Input()
  public confirmBtnText: string = 'Salir';

  @Input()
  public confirmBtnClass: string = 'btn-primary';

  @Input()
  public isLoading: boolean = false;

  @Input()
  public closeOnConfirm: boolean = true;

  public showIconNimation = false;

  @Output()
  public confirmEvent = new EventEmitter();

  constructor(private modalService: ModalService) {}

  public openModal() {
    this.showIconNimation = true;
    this.modalService.open(this.modalId);
  }

  public closeModal() {
    this.modalService.close(this.modalId);
  }

  public onConfirm() {
    if (!this.isLoading) {
      this.confirmEvent.emit();
    }
    if (this.closeOnConfirm) {
      this.closeModal();
    }
  }
}
